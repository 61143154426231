<template>
    <div class='contain'>
        <searchBar :searchInput="false"></searchBar>
        <div class="bgf flex_c upload-cart">
            <div class="flex_1" style="width:670px">
                <el-steps :active="steps" align-center style="width:840px;margin:0 auto 50px;">
                    <el-step title="上传文件" description="请上传指定格式文件" :class="{in:steps>=2}"></el-step>
                    <el-step title="校验数据" description="检查数据格式是否正确" :class="{in:steps>=3}"></el-step>
                    <el-step title="完税支付" description="确认信息完税支付" :class="{in:steps>=4}"></el-step>
                </el-steps>
                <template v-if="steps==1">
                    <div style="position: relative;">
                        <el-upload ref="upload" class="upload" accept=".xlsx" drag :action="`${baseApi}/ent/v3/taxes/import`" :headers="headers" :limit="2" :on-change="onChange" :auto-upload="false" :on-error="onError" :on-success="handleUploadSuccess" :on-remove="onRemove">
                            <img src="@/assets/imgs/icon/upload-icon.png" class="icon" alt="">
                            <div class="el-upload__text">
                                <div class="color fs_16">上传文件</div>
                                <div class="el-upload__tip">
                                    <p>上传文件格式仅支持xls，xlsx，且文件大小不能超过5M，</p>
                                    <p>请按照规定格式填写数据，每次填写数据总和不超过10000条</p>
                                </div>
                            </div>
                        </el-upload>
                        <a href="/file/完税申请导入模板.xlsx" download="" class="download-btn color fs_14">下载模版</a>
                    </div>
                    <div class="flex_cen">
                        <el-button type="primary" class="next-btn" :disabled="!hasFile" :loading="sandLoading" @click="handleNext">下一步</el-button>
                    </div>
                </template>

                <!-- <el-divider v-if="steps>1"></el-divider> -->
                <template v-if="steps==2">
                    <taxpayer-info perfect></taxpayer-info>
                    <div class="fs_12 info_color mb_15" style="margin-top:30px;">
                        共导入数据 {{ excelData.total_num }} 条，其中有 {{ excelData.error_num || 0 }} 条数据错误
                    </div>
                    <form-table show-page ref="formTable"></form-table>

                    <div class="flex_cen mt_15">
                        <el-button type="info" class="next-btn mr_20" @click="handlePre">上一步</el-button>
                        <el-button type="primary" class="next-btn" :loading="sandLoading" :disabled="disabled" @click="ruleForms(excelData.error_num>0?'before_check':'batch_add')">{{ excelData.error_num>0?'重新校验':'确认提交' }}</el-button>
                    </div>
                </template>

                <template v-if="steps==3">
                    <detail-table ref="detailTable"></detail-table>
                    <div class="flex_cen mt_15">
                        <el-button type="primary" class="submit mr_20 fs_16" @click="creatOrder">下一步</el-button>
                    </div>
                </template>
            </div>
        </div>
    </div>
</template>

<script>
import { getToken } from '@/common/cache'
import formTable from './components/form-table'
import taxpayerInfo from './components/taxpayer-info'
import detailTable from './components/detail-table'
import { s_setItem } from "@/common/cache"
export default {
	name: 'TaxesBulkImport',
	components: { formTable, taxpayerInfo, detailTable },
	data() {
		return {
			sandLoading: false,
			baseApi: process.env.VUE_APP_API,
			headers: {
				'Authorization': 'Bearer ' + getToken()
			},
			hasFile: false, // excel文件
			steps: 1,
			excelData: {},
			forms: {
				tableData: []
			},
			amount: {},
			disabled: false
		}
	},
	created() {

	},
	methods: {
		// Excel格式判断
		onChange(file, fileList) {
			let extension = file.name.substring(file.name.lastIndexOf('.') + 1)
			let size = file.size / 1024 / 1024
			if (extension !== 'xlsx') {
				this.$message.warning('请上传格式为 .xlsx 的文件')
				this.$refs.upload.clearFiles()
				return false
			}
			if (size > 5) {
				this.$message.warning('文件大小不能超过5M')
				this.$refs.upload.clearFiles()
				return false
			}
			if (fileList.length > 1) {
				fileList.shift()
			}
			this.$nextTick(() => {
				this.hasFile = true
			})
		},
		onError() {
			this.sandLoading = false
		},
		onRemove() {
			this.hasFile = false
		},
		// Excel上传成功
		handleUploadSuccess(res) {
			if (res.code === 200) {
				this.excelData = res.data
				this.sandLoading = false
				res.data.list.forEach((v, i)=>{
					v.index = i+1
				})
				this.steps++
				this.$nextTick(()=>{
					this.$refs.formTable.forms.tableData = res.data.list
					setTimeout(()=>{
						this.$refs.formTable.ruleForms()
					}, 200)
				})
			} else {
				this.hasFile = false
				this.sandLoading = false
				this.$refs.upload.clearFiles()
				this.$message.error(res.msg)
			}
		},
		// 下一步
		handleNext() {
			this.sandLoading = true
			this.$refs.upload.submit()
		},
		// 上一步
		handlePre() {
			// this.$refs.formTable.forms.tableData = []
			this.steps--
			this.sandLoading = false
			this.hasFile = false
		},

		// Excel数据导入
		ruleForms(api) {
			this.$refs.formTable.ruleForms(()=>{
				this.sandLoading = true
				let formData = this.$refs.formTable.forms.tableData
				let data = { import: formData }
				this.$axios({
					url: `/ent/v3/taxes/import/${api}`,
					method: 'POST',
					data,
					hideLoading: true,
					shake: true
				}).then(res => {
					setTimeout(() => {
						this.sandLoading = false
					}, 900)
					if (res.code === 200) {
						this.excelData = res.data
						this.$refs.formTable.forms.tableData = res.data.list
						if (!res.data.error_num && api=='batch_add') {
							this.steps++
							this.$nextTick(()=>{
								this.$refs.detailTable.tableData = formData

								let amount = res.data.amount
								amount.synthesis_fee = Number(amount.service_fee)*100 + Number(amount.tax_fee)*100
								amount.synthesis_fee = (amount.synthesis_fee/100).toFixed(2)
								this.$refs.detailTable.amount = amount
								amount.id = res.data.taxes_id

								this.amount = amount
							})
						}
					}
				})
			})
		},

		backList() {
			this.$router.go(-1)
		},
		creatOrder() {
			s_setItem('amount', this.amount)
			this.$router.push(`/payTaxes/order/${this.amount.id}`)
		}

	}
}
</script>

<style lang='less' scoped>
@import "~@/assets/style/upload.less";
.submit {
    width: 200px;
    line-height: 24px;
}
</style>